const THREE = require('three');
const texLoader = new THREE.TextureLoader();

function loadTexs(imgs, callback) {
  const length = Object.keys(imgs).length;
  const loadedTexs = {};
  let count = 0;

  for (var key in imgs) {
    const k = key;
    if (imgs.hasOwnProperty(k)) {
      texLoader.load(imgs[k], (tex) => {
        tex.repeat = THREE.RepeatWrapping;
        loadedTexs[k] = tex;
        count++;
        if (count >= length) callback(loadedTexs);
      });
    }
  }
}

const Fog = require('./Fog').default;

function intro() {
  // ==========
  // Define common variables
  //
  const resolution = new THREE.Vector2();
  const canvas = document.getElementById('canvas-webgl');
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: canvas,
  });
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera();
  const clock = new THREE.Clock();

  camera.far = 50000;
  camera.setFocalLength(24);

  // ==========
  // Define unique variables
  //
  const texsSrc = {
    team: '/imgs/tfd-smoke.jpg',
    fog: '/imgs/fog.png'
  };
  const fog = new Fog();

  // ==========
  // Define functions
  //
  const render = () => {
    const time = clock.getDelta();
    fog.render(time);
    renderer.render(scene, camera);
  };
  const renderLoop = () => {
    render();
    requestAnimationFrame(renderLoop);
  };
  const resizeCamera = () => {
    camera.aspect = resolution.x / resolution.y;
    camera.updateProjectionMatrix();
  };
  const resizeWindow = () => {
    resolution.set(document.body.clientWidth, window.innerHeight);
    canvas.width = resolution.x;
    canvas.height = resolution.y;
    resizeCamera();
    renderer.setSize(resolution.x, resolution.y);
  };
  const on = () => {
    // debounce this
    window.addEventListener('resize', resizeWindow);
  };

  // ==========
  // Initialize
  //
  const init = () => {
    loadTexs(texsSrc, (loadedTexs) => {

      canvas.style.backgroundImage = `url('/imgs/tfd-smoke.jpg')`;
      fog.createObj(loadedTexs.fog);

      scene.add(fog.obj);


      renderer.setClearColor(0x000000, 0.0);
      // renderer.setClearColor(0x000000, 0.0);
      camera.position.set(0, 0, 1000);
      camera.lookAt(new THREE.Vector3());
      clock.start();

      on();
      resizeWindow();
      renderLoop();
    });
  }
  init();
}
console.log(THREE.WebGLRenderer);;
window.onload = intro;