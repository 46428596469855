import { initializeStockTicker } from './stock-ticker.js';
import { initializeLiveCam } from './live-cam.js';

const THREE = require('three');
const texLoader = new THREE.TextureLoader();

function loadTexs(imgs, callback) {
  const length = Object.keys(imgs).length;
  const loadedTexs = {};
  let count = 0;

  for (var key in imgs) {
    const k = key;
    if (imgs.hasOwnProperty(k)) {
      texLoader.load(imgs[k], (tex) => {
        tex.repeat = THREE.RepeatWrapping;
        loadedTexs[k] = tex;
        count++;
        if (count >= length) callback(loadedTexs);
      });
    }
  }
}

const Fog = require('./Fog').default;

function intro() {
  // ==========
  // Define common variables
  //
  const resolution = new THREE.Vector2();
  const canvas = document.getElementById('canvas-webgl');
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: canvas,
  });
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera();
  const clock = new THREE.Clock();

  camera.far = 50000;
  camera.setFocalLength(24);

  // ==========
  // Define unique variables
  //
  const texsSrc = {
    team: `/imgs/unnamed-1.jpg?t=${Date.now()}`,
    fog: '/imgs/fog.png',
  };
  const fog = new Fog();

  // ==========
  // Define functions
  //
  const render = () => {
    const time = clock.getDelta();
    fog.render(time);
    renderer.render(scene, camera);
  };
  const renderLoop = () => {
    render();
    requestAnimationFrame(renderLoop);
  };
  const resizeCamera = () => {
    camera.aspect = resolution.x / resolution.y;
    camera.updateProjectionMatrix();
  };
  const resizeWindow = () => {
    resolution.set(document.body.clientWidth, window.innerHeight);
    canvas.width = resolution.x;
    canvas.height = resolution.y;
    resizeCamera();
    renderer.setSize(resolution.x, resolution.y);
  };
  const on = () => {
    // debounce this
    window.addEventListener('resize', resizeWindow);
  };

  // ==========
  // Initialize
  //
  const init = () => {
    loadTexs(texsSrc, (loadedTexs) => {
      canvas.style.backgroundImage = `url('/imgs/unnamed-1.jpg?t=${Date.now()}')`;
      fog.createObj(loadedTexs.fog);

      scene.add(fog.obj);

      renderer.setClearColor(0x000000, 0.0);
      // renderer.setClearColor(0x000000, 0.0);
      camera.position.set(0, 0, 1000);
      camera.lookAt(new THREE.Vector3());
      clock.start();

      on();
      resizeWindow();
      renderLoop();
    });
  };
  init();
}

function initializeSenderForm() {
  const senderForm = document.querySelector('[data-sender-form-id]');
  let userHidden = false;

  senderForm.addEventListener('click', () => {
    document.querySelector('.sender-form-field').classList.add('hidden');
    userHidden = true;
    window.removeEventListener('scroll', checkScroll);
  });
  function checkScroll() {
    if (window.scrollY > 350) {
      if (document.querySelector('.sender-form-field').classList.contains('hidden')) {
        document.querySelector('.sender-form-field').classList.remove('hidden');
      }
    } else {
      if (!document.querySelector('.sender-form-field').classList.contains('hidden')) {
        document.querySelector('.sender-form-field').classList.add('hidden');
      }
    }
  }
  window.addEventListener('scroll', checkScroll);
}
// console.log(THREE.WebGLRenderer);
window.onload = intro;

document.addEventListener('DOMContentLoaded', () => {
  initializeStockTicker();
  initializeLiveCam();
  initializeSenderForm();

  // Start loading sequence
  const mainContent = document.getElementById('main-content');
  const loadingOverlay = document.getElementById('loading-overlay');
  const canvas = document.getElementById('canvas-webgl');

  // Function to handle the loading sequence
  const handleLoadingSequence = () => {
    // First ensure the ticker is visible (it should be due to CSS, but let's be sure)
    document.querySelector('.ticker-wrap').style.opacity = '1';
    
    // Show the loading spinner after a short delay
    setTimeout(() => {
      loadingOverlay.classList.add('visible');
    }, 500);

    // Wait for images to load
    const images = document.querySelectorAll('img');
    const imagePromises = Array.from(images).map(img => {
      if (img.complete) return Promise.resolve();
      return new Promise(resolve => {
        img.onload = resolve;
        img.onerror = resolve; // Handle error case as well
      });
    });

    // Wait for WebGL canvas to be ready
    const canvasReady = new Promise(resolve => {
      let attempts = 0;
      const maxAttempts = 50; // Prevent infinite loop
      
      const checkCanvas = () => {
        attempts++;
        // Check if canvas has content (width, height, and backgroundImage or children)
        const isReady = canvas.width > 0 && 
                       canvas.height > 0 && 
                       (canvas.style.backgroundImage || canvas.children.length > 0);
        
        if (isReady || attempts >= maxAttempts) {
          resolve();
        } else {
          requestAnimationFrame(checkCanvas);
        }
      };
      checkCanvas();
    });

    // When everything is loaded
    Promise.all([...imagePromises, canvasReady])
      .then(() => {
        // Add loaded class to canvas first
        canvas.classList.add('loaded');
        
        // Short delay before showing main content
        setTimeout(() => {
          // Show main content
          mainContent.classList.add('loaded');
          // Hide loading overlay
          loadingOverlay.classList.add('hidden');
        }, 500);
      })
      .catch(error => {
        // If there's an error, still show the content
        console.error('Loading error:', error);
        canvas.classList.add('loaded');
        mainContent.classList.add('loaded');
        loadingOverlay.classList.add('hidden');
      });
  };

  // Start the loading sequence
  handleLoadingSequence();
});
